// Import our CSS
import styles from "../css/app.pcss";

import VueAxios from "../js/modules/vue-axios";
import SlideUpDown from "vue-slide-up-down";
import VueLazyload from "vue-lazyload";

import VueRouter from "vue-router";
import VueHead from "vue-head";

import CartMixin from "../vue/mixins/Cart";
import HelpersMixin from "../vue/mixins/Helpers";
import MatchHeightMixin from "../vue/mixins/MatchHeight";

import { client } from "@samuells/vue-tailwindcss-responsive-directive";
import Sticky from "vue-sticky-directive";
import TextareaAutosize from "vue-textarea-autosize";
import VueScrollTo from "vue-scrollto";
import VueCarousel from "vue-carousel";
import ClickOutside from "vue-click-outside";

import Promise from "core-js/features/promise";
import "regenerator-runtime/runtime";

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");

  Vue.directive("screen", client);
  Vue.directive("Sticky", Sticky);
  Vue.component("slide-up-down", SlideUpDown);
  Vue.use(VueHead);
  Vue.use(VueRouter);
  Vue.use(VueScrollTo);
  Vue.use(TextareaAutosize);
  Vue.use(VueLazyload, {
    lazyComponent: true,
    preLoad: 1.6,
    error: "/dist/img/lazy-error.png",
    attempt: 1,
  });
  Vue.use(VueCarousel);

  // Create our vue instance
  const vm = new Vue({
    el: "#app",
    mixins: [CartMixin, HelpersMixin, MatchHeightMixin],
    directives: { ClickOutside },
    components: {
      AddressVerificationModal: () =>
        import(/* webpackChunkName: "address-verification-modal" */ "../vue/_new/AddressVerificationModal.vue"),
      AnimalAcoustics: () => import(/* webpackChunkName: "animal-acoustics" */ "../vue/pages/AnimalAcoustics.vue"),
      AnnouncementBar: () => import(/* webpackChunkName: "announcement-bar" */ "../vue/components/AnnouncementBar.vue"),
      AuthorProfile: () => import(/* webpackChunkName: "author-profile" */ "../vue/components/AuthorProfile.vue"),
      BaseAnalytics: () => import(/* webpackChunkName: "base-analytics" */ "../vue/components/BaseAnalytics.vue"),
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../vue/components/BaseButton.vue"),
      BaseCart: () => import(/* webpackChunkName: "base-cart" */ "../vue/shop/BaseCart.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../vue/components/BaseHeading.vue"),
      BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../vue/components/BaseIcon.vue"),
      BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../vue/components/BaseRichText.vue"),
      BaseVideo: () => import(/* webpackChunkName: "base-video" */ "../vue/components/BaseVideo.vue"),
      BlockAudio: () => import(/* webpackChunkName: "block-audio" */ "../vue/blocks/BlockAudio.vue"),
      BlockBoxedSideBySideImageAndText: () =>
        import(/* webpackChunkName: "block-boxed-side-by-side-image-and-text" */ "../vue/blocks/BlockBoxedSideBySideImageAndText.vue"),
      BlockBoxedLargeImageWithButtonsAndText: () =>
        import(
          /* webpackChunkName: "block-boxed-large-image-with-buttons-and-text" */ "../vue/blocks/BlockBoxedLargeImageWithButtonsAndText.vue"
        ),
      BlockCalloutHandwritten: () =>
        import(/* webpackChunkName: "block-callout-handwritten" */ "../vue/blocks/BlockCalloutHandwritten.vue"),
      BlockCalloutIcons: () => import(/* webpackChunkName: "block-callout-icons" */ "../vue/blocks/BlockCalloutIcons.vue"),
      BlockComparisonChartThreeColumn: () =>
        import(/* webpackChunkName: "block-comparision-chart-three-column" */ "../vue/blocks/BlockComparisonChartThreeColumn.vue"),
      BlockCustomTable: () => import(/* webpackChunkName: "block-custom-table" */ "../vue/blocks/BlockCustomTable.vue"),
      BlockExpandableIconBlocks: () =>
        import(/* webpackChunkName: "block-expandable-icon-blocks" */ "../vue/blocks/BlockExpandableIconBlocks.vue"),
      BlockFeaturedContent: () => import(/* webpackChunkName: "block-featured-content" */ "../vue/blocks/BlockFeaturedContent.vue"),
      BlockHeading: () => import(/* webpackChunkName: "block-heading" */ "../vue/blocks/BlockHeading.vue"),
      BlockIconColumns: () => import(/* webpackChunkName: "block-icon-columns" */ "../vue/blocks/BlockIconColumns.vue"),
      BlockIconsGrid: () => import(/* webpackChunkName: "block-icons-grid" */ "../vue/blocks/BlockIconsGrid.vue"),
      BlockImage: () => import(/* webpackChunkName: "block-image" */ "../vue/blocks/BlockImage.vue"),
      BlockImageColumns: () => import(/* webpackChunkName: "block-image-columns" */ "../vue/blocks/BlockImageColumns.vue"),
      BlockImagesGrid: () => import(/* webpackChunkName: "block-images-grid" */ "../vue/blocks/BlockImagesGrid.vue"),
      BlockImagesTower: () => import(/* webpackChunkName: "block-image" */ "../vue/blocks/BlockImagesTower.vue"),
      BlockLogosBar: () => import(/* webpackChunkName: "block-logos-bar" */ "../vue/blocks/BlockLogosBar.vue"),
      BlockMixedContentWithSidebar: () =>
        import(/* webpackChunkName: "block-mixed-content-with-sidebar" */ "../vue/blocks/BlockMixedContentWithSidebar.vue"),
      BlockPicsCta: () => import(/* webpackChunkName: "block-pics-cta" */ "../vue/blocks/BlockPicsCta.vue"),
      BlockPosts: () => import(/* webpackChunkName: "block-posts" */ "../vue/blocks/BlockPosts.vue"),
      BlockProductsList: () => import(/* webpackChunkName: "block-products-list" */ "../vue/blocks/BlockProductsList.vue"),
      BlockTimeline: () => import(/* webpackChunkName: "block-timeline" */ "../vue/blocks/BlockTimeline.vue"),
      BlockRichText: () => import(/* webpackChunkName: "block-rich-text" */ "../vue/blocks/BlockRichText.vue"),
      BlogEntryHeader: () => import(/* webpackChunkName: "blog-entry-header" */ "../vue/blog/BlogEntryHeader.vue"),
      BlogIndex: () => import(/* webpackChunkName: "blog-index" */ "../vue/blog/BlogIndex.vue"),
      CheckoutResellersModal: () =>
        import(/* webpackChunkName: "checkout-resellers-modal" */ "../vue/shop/CheckoutResellersModal.vue"),
      CtaButtons: () => import(/* webpackChunkName: "cta-buttons" */ "../vue/components/CtaButtons.vue"),
      CtaSubscribe: () => import(/* webpackChunkName: "cta-subscribe" */ "../vue/components/CtaSubscribe.vue"),
      ComparisonChart: () => import(/* webpackChunkName: "comparison-chart" */ "../vue/components/ComparisonChart.vue"),
      ContactDetails: () => import(/* webpackChunkName: "contact-details" */ "../vue/pages/ContactDetails.vue"),
      CustomerStoriesIndex: () =>
        import(/* webpackChunkName: "customer-stories-index" */ "../vue/customer-stories/CustomerStoriesIndex.vue"),
      DownloadsIndex: () => import(/* webpackChunkName: "software-downloads" */ "../vue/downloads/DownloadsIndex.vue"),
      EventsIndex: () => import(/* webpackChunkName: "events-index" */ "../vue/events/EventsIndex.vue"),
      FaqsIndex: () => import(/* webpackChunkName: "faqs-index" */ "../vue/faqs/FaqsIndex.vue"),
      FaqsOverview: () => import(/* webpackChunkName: "faqs-overview" */ "../vue/faqs/FaqsOverview.vue"),
      FormCheckbox: () => import(/* webpackChunkName: "form-checkbox" */ "../vue/components/form/FormCheckbox.vue"),
      FormInput: () => import(/* webpackChunkName: "form-input" */ "../vue/components/form/FormInput.vue"),
      FormRadioGroup: () => import(/* webpackChunkName: "form-radio-group" */ "../vue/components/form/FormRadioGroup.vue"),
      FormSelect: () => import(/* webpackChunkName: "form-select" */ "../vue/components/form/FormSelect.vue"),
      GlossaryIndex: () => import(/* webpackChunkName: "glossary-index" */ "../vue/glossary/GlossaryIndex.vue"),
      GlossaryTerm: () => import(/* webpackChunkName: "glossary-term" */ "../vue/glossary/GlossaryTerm.vue"),
      GrantProgram: () => import(/* webpackChunkName: "grant-program" */ "../vue/grant-program/GrantProgram.vue"),
      IconDownload: () => import(/* webpackChunkName: "icon-download" */ "../vue/icons/IconDownload.vue"),
      IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../vue/icons/IconLoading.vue"),
      LargeImageHeader: () => import(/* webpackChunkName: "large-image-header" */ "../vue/components/headers/LargeImageHeader.vue"),
      NewsIndex: () => import(/* webpackChunkName: "news-index" */ "../vue/news/NewsIndex.vue"),
      PaymentForm: () => import(/* webpackChunkName: "payment-form" */ "../vue/components/PaymentForm.vue"),
      ProductContent: () => import(/* webpackChunkName: "product-content" */ "../vue/product/ProductContent.vue"),
      ProductHeader: () => import(/* webpackChunkName: "product-header" */ "../vue/product/ProductHeader.vue"),
      ProductImages: () => import(/* webpackChunkName: "product-images" */ "../vue/product/ProductImages.vue"),
      PublicationsIndex: () => import(/* webpackChunkName: "publications-index" */ "../vue/publications/PublicationsIndex.vue"),
      ResellersIndex: () => import(/* webpackChunkName: "resellers-index" */ "../vue/resellers/ResellersIndex.vue"),
      SidebarBlocks: () => import(/* webpackChunkName: "sidebar-blocks" */ "../vue/sidebar/SidebarBlocks.vue"),
      SidebarOrderSummary: () => import(/* webpackChunkName: "sidebar-order-summary" */ "../vue/shop/SidebarOrderSummary.vue"),
      SimpleHeader: () => import(/* webpackChunkName: "simple-header" */ "../vue/components/headers/SimpleHeader.vue"),
      SiteFooter: () => import(/* webpackChunkName: "site-footer" */ "../vue/components/site-footer/SiteFooter.vue"),
      SiteHeader: () => import(/* webpackChunkName: "block-pics-cta" */ "../vue/components/headers/SiteHeader.vue"),
      SiteSearch: () => import(/* webpackChunkName: "site-search" */ "../vue/search/SiteSearch.vue"),
      StoreIndex: () => import(/* webpackChunkName: "store-index" */ "../vue/shop/StoreIndex.vue"),
      SwipeTableIndicator: () => import(/* webpackChunkName: "swipe-table-indicator" */ "../vue/components/SwipeTableIndicator.vue"),
      SalesForm: () => import(/* webpackChunkName: "sales-form" */ "../vue/_new/SalesForm.vue"),
      SupportForm: () => import(/* webpackChunkName: "support-form" */ "../vue/_new/SupportForm.vue"),
      TrainingCourse: () => import(/* webpackChunkName: "training-course" */ "../vue/training-courses/TrainingCourse.vue"),
      TrainingCoursesIndex: () =>
        import(/* webpackChunkName: "training-courses-index" */ "../vue/training-courses/TrainingCoursesIndex.vue"),
      UserGuides: () => import(/* webpackChunkName: "user-guides" */ "../vue/pages/UserGuides.vue"),
      VideoTutorialsIndex: () =>
        import(/* webpackChunkName: "video-tutorials-index" */ "../vue/video-tutorials/VideoTutorialsIndex.vue"),

      /* EM: Adding new Vue components */
      FeaturedProduct: () => import(/* webpackChunkName: "featured-product" */ "../vue/_new/FeaturedProduct.vue"),
      SidebarAccordion: () => import(/* webpackChunkName: "sidebar-accordion" */ "../vue/_new/SidebarAccordion.vue"),
      Signup: () => import(/* webpackChunkName: "signup" */ "../vue/_new/Signup.vue"),
      CtaSignup: () => import(/* webpackChunkName: "cta-signup" */ "../vue/_new/CtaSignup.vue"),
      AccessoriesIndex: () => import(/* webpackChunkName: "accessories-index" */ "../vue/accessories/AccessoriesIndex.vue"),
    },
    data: function () {
      return {
        csrfTokenExists: false,
        csrfTokenName: "",
        csrfTokenValue: "",
        cart: null,
        formIsLoading: false,
        ajaxError: "",
        emailMessage: {},
        checkout: {
          shipping: {
            statesLabel: "State/Province",
            stateValue: "",
            states: null,
            zipPlaceholder: "ZIP/Postal Code",
            zipRequired: false,
          },
          billing: {
            statesLabel: "State/Province",
            stateValue: "",
            states: null,
            zipPlaceholder: "ZIP/Postal Code",
            zipRequired: false,
          },
          shipOverride: false,
          shipOverrideMethod: null,
          shipOverrideRate: null,
          shipMethods: [],
        },
      };
    },
    methods: {
      async emailFieldOnChange(formType, event) {
        let self = this;
        const email = event.target.value;
        const currentEmail = formType === 'billing' ? self.cart.billTo.email : self.cart.shipTo.email;

        // Don't try anything if the email did not change
        if (currentEmail === email) {
          return;
        }

        if (formType === "billing") {
          self.cart.billTo.email = email;
        } else {
          self.cart.shipTo.email = email;
        }

        // We must debounce the email verification
        if (self.emailMessage.timeout) {
          clearTimeout(self.emailMessage.timeout);
          self.emailMessage.timeout = false;
        }

        self.emailMessage.timeout = setTimeout(async () => {
          this.formIsLoading = true;
          self.emailMessage = { status: "pending", message: "Verifying your email address" };
          self.emailMessage = await self.verifyEmail(email);
          this.formIsLoading = false;
        }, 1000);
      },
      // on change of country, state and zip fields on shipping/billing pages
      addressFormFieldOnChange(addressType, fieldName) {
        let self = this;
        self.formIsLoading = true;
        self
          .updateAddressFormData(addressType, fieldName)
          .then(function () {
            self.formIsLoading = false;
          })
          .catch(function (error) {
            self.showAddressFormError();
            self.formIsLoading = false;
          });

        if (addressType === "shipping" && fieldName === "country") {
          self.checkForEORIRequired();
          self.checkForResellersOnCheckoutShipping(true);
        }
      },

      checkForEORIRequired() {
        let self = this;
        let countryField = document.getElementById("country_field"),
          messageContainer = document.getElementById("eori-field"),
          selectedCountry = countryField ? countryField.value : null;
        if (!messageContainer) {
          return;
        }

        if (
          selectedCountry == "AUSTRIA" ||
          selectedCountry == "BELGIUM" ||
          selectedCountry == "BULGARIA" ||
          selectedCountry == "CROATIA" ||
          selectedCountry == "CYPRUS" ||
          selectedCountry == "CZECH REPUBLIC" ||
          selectedCountry == "DENMARK" ||
          selectedCountry == "ESTONIA" ||
          selectedCountry == "FINLAND" ||
          selectedCountry == "FRANCE" ||
          selectedCountry == "MONACO" ||
          selectedCountry == "GERMANY" ||
          selectedCountry == "GREECE" ||
          selectedCountry == "HUNGARY" ||
          selectedCountry == "IRELAND" ||
          selectedCountry == "ITALY" ||
          selectedCountry == "LATVIA" ||
          selectedCountry == "LITHUANIA" ||
          selectedCountry == "LUXEMBOURG" ||
          selectedCountry == "MALTA" ||
          selectedCountry == "NETHERLANDS" ||
          selectedCountry == "POLAND" ||
          selectedCountry == "PORTUGAL" ||
          selectedCountry == "ROMANIA" ||
          selectedCountry == "SLOVAKIA" ||
          selectedCountry == "SLOVENIA" ||
          selectedCountry == "SPAIN" ||
          selectedCountry == "SWEDEN"
        ) {
          messageContainer.hidden = false;
          messageContainer.required = true;
        } else {
          messageContainer.hidden = true;
          messageContainer.required = false;
        }
      },

      // Checkout resellers pop-up
      checkForResellersOnCheckoutShipping(autoOpenModal) {
        let self = this;
        let countryField = document.getElementById("country_field"),
          messageContainer = document.getElementById("shipping-country-message"),
          messageContainerEvent = null,
          selectedCountry = countryField ? countryField.value : null;
        if (messageContainer) {
          messageContainer.innerHTML = "";
          messageContainer.classList.add("hidden");
        }
        if (messageContainerEvent) {
          messageContainerEvent.removeEventListener("click");
        }
        if (selectedCountry && selectedCountry !== "" && selectedCountry !== "UNITED STATES") {
          const formData = new FormData();
          formData.append("country", selectedCountry);
          formData.append(self.csrfTokenName, self.csrfTokenValue);

          let i = 0;
          for (const [productCode, value] of Object.entries(self.cart.items)) {
            formData.append(`cartItems[${i}][productCode]`, productCode);
            formData.append(`cartItems[${i}][qty]`, value.qty);
            i++;
          }

          let ajaxUrl = "/actions/site-module/site/get-resellers-for-checkout";
          VueAxios.post(ajaxUrl, formData).then((response) => {
            let data = response.data || null;
            if (data.length > 0) {
              if (autoOpenModal) {
                self.$root.$emit("open-checkout-resellers-modal", {
                  resellers: data,
                  country: selectedCountry,
                });
              }
              if (messageContainer) {
                messageContainer.innerHTML = "View authorized reseller" + (data.length > 1 ? "s" : "") + " in " + selectedCountry;
                messageContainer.classList.remove("hidden");
                messageContainerEvent = messageContainer.addEventListener("click", function () {
                  self.$root.$emit("open-checkout-resellers-modal", {
                    resellers: data,
                    country: selectedCountry,
                  });
                });
              }
            }
          });
        }
      },

      // show address form error (something went wrong updating the shipping/billing address form)
      showAddressFormError() {
        this.ajaxError = "An error occurred updating the form. Please try again or contact us if the problem persists.";
        let addressType = self.getCurrentAddressForm();
        this.scrollTo("checkout-" + addressType + "-form");
      },

      // get checkout form data (get states, shipping methods, etc)
      getCheckoutFormData(addressType) {
        let self = this,
          formData = new FormData();
        self.formIsLoading = true;
        window.csrfPromise.then(function () {
          formData.append(self.csrfTokenName, self.csrfTokenValue);

          let options = {
            method: "POST",
            data: formData,
            url: "/actions/wildlife/shop/checkout-get-form-data",
          };
          self.ajaxError = "";
          VueAxios(options)
            .then((response) => {
              let data = response.data || null;
              self.formIsLoading = false;
              if (data && data.success) {
                self.updateCheckoutFormData(data);
              } else {
                self.showAddressFormError();
              }
            })
            .catch(function (error) {
              self.showAddressFormError();
              self.formIsLoading = false;
            });
        });
      },

      // update address form data for shipping/billing pages in cart
      updateAddressFormData(addressType, changefield) {
        let self = this;
        return new Promise(function (resolve, reject) {
          let formData = new FormData();
          formData.append(self.csrfTokenName, self.csrfTokenValue);
          formData.append("action", "wildlife/shop/checkout-update-address-fields");
          if (addressType === "shipping") {
            formData.append("field", "shipTo");
          } else if (addressType === "billing") {
            formData.append("field", "billTo");
          }

          // shipping method
          if (addressType === "shipping") {
            let shippingMethodField = document.getElementById("shipping_method");
            if (shippingMethodField) {
              formData.append("shipmethod", shippingMethodField.value);
            }
          }

          // country
          let countryField = document.getElementById("country_field");
          if (countryField) {
            formData.append("country", countryField.value);
          }

          // state
          let stateFieldSelect = document.getElementById("state_field_select"),
            stateFieldText = document.getElementById("state_field_text");
          if (stateFieldSelect) {
            formData.append("state", stateFieldSelect.value);
          } else if (stateFieldText) {
            formData.append("state", stateFieldText.value);
          }

          // zip
          let zipField = document.getElementById("zip_field");
          if (zipField) {
            formData.append("zip", zipField.value);
          }

          // changefield
          if (changefield) {
            let changefieldElement = document.getElementById(changefield);

            if (changefieldElement) {
              formData.append(changefield, changefieldElement.value);
            }

            formData.append("changefield", changefield);
          }

          // send data
          let options = {
            method: "POST",
            data: formData,
            url: "/",
          };
          self.ajaxError = "";
          VueAxios(options)
            .then((response) => {
              let data = response.data || null,
                cart = data.cart || null,
                checkoutData = data.checkoutData || null;
              if (data.success && cart) {
                // set new cart data
                self.cart = cart;

                // set new checkout data
                if (checkoutData) {
                  self.updateCheckoutFormData(checkoutData);
                }
                resolve();
              } else {
                reject();
              }
            })
            .catch(function (error) {
              reject(error);
            });
        });
      },

      // update checkout form data
      updateCheckoutFormData(data) {
        let self = this;

        // updates checkout object with new data (includes states, shipping methods, etc)
        self.checkout = data;

        // on next tick (after fields update)
        self.$nextTick(function () {
          let addressType = self.getCurrentAddressForm();

          // set zip field
          document.getElementById("zip_field").value = data[addressType].zipValue;

          // set state field
          let stateValue = data[addressType].stateValue,
            stateFieldSelect = document.getElementById("state_field_select"),
            stateFieldText = document.getElementById("state_field_text");

          if (stateFieldSelect) {
            // set state select
            document.querySelector('#state_field_select [value="' + stateValue + '"]').selected = true;
          } else if (stateFieldText) {
            // set state text field
            stateFieldText.value = stateValue;
          }
        });
      },

      // on change of shipping method select
      shippingFormShippingMethodOnChange() {
        // shipping method field changed
        let self = this;
        self.formIsLoading = true;
        self
          .updateAddressFormData("shipping", null)
          .then(function () {
            self.formIsLoading = false;
          })
          .catch(function (error) {
            self.showAddressFormError();
            self.formIsLoading = false;
          });
      },

      // scroll to id
      scrollTo(id) {
        VueScrollTo.scrollTo("#" + id, 350, {
          offset: -10,
        });
      },

      // print page
      printPage() {
        window.print();
      },

      // get current address form (shipping or billing)
      getCurrentAddressForm() {
        if (document.getElementById("checkout-shipping-form")) {
          return "shipping";
        } else if (document.getElementById("checkout-billing-form")) {
          return "billing";
        } else {
          return null;
        }
      },

      // scroll to element by id
      scrollToId(id) {
        let self = this,
          target = document.getElementById(id);
        if (target) {
          // check if target is inside a closed product-detail-section
          let productSection = target.closest(".product-detail-section");
          if (!productSection) {
            // not inside a product-detail-section
            target.scrollIntoView();
          }
          if (productSection) {
            // inside a product-detail-section
            if (productSection.classList.contains("opened")) {
              // section is open, scroll there
              target.scrollIntoView();
            } else {
              // section is closed, hold on
              let sectionId = productSection.getAttribute("data-section-id");
              // emit event to open section
              self.$root.$emit("open-product-detail-section", sectionId);
              setTimeout(function () {
                target.scrollIntoView();
              }, 500);
            }
          }
        }
      },

      async verifyEmail(email) {
        if (!email) {
          return {};
        }

        let self = this;
        const formData = new FormData();

        formData.append("action", "wildlife/shop/verify-email");
        formData.append(self.csrfTokenName, self.csrfTokenValue);
        formData.append("email", email);

        let options = {
          method: "POST",
          data: formData,
          url: "/",
        };

        self.ajaxError = "";

        let result = {};

        try {
          const response = await VueAxios(options);
          const data = response.data;

          result = data.success ? 
            { status: "success", message: data.msg ?? "The email you provided was verified successfully." } :
            { status : "error", message: data.msg ?? "We are unable to validate your email address.  Please try a different address." };
        } catch (error) {
          result = {
            status: "error",
            message: error.response.data?.message ?? "We are unable to validate your email address.  Please try a different address.",
          };
        }

        return result;
      },

      async verifyAddress(input) {
        if (!input) {
          return;
        }

        let self = this;
        const formData = new FormData();

        formData.append("action", "wildlife/shop/verify-address");
        formData.append(self.csrfTokenName, self.csrfTokenValue);

        Object.keys(input).forEach((key) => {
          formData.append(key, input[key]);
        });

        let options = {
          method: "POST",
          data: formData,
          url: "/",
        };

        self.ajaxError = "";

        let results = null;

        try {
          const response = await VueAxios(options);

          const data = response.data || null,
            suggested = data.suggested || null,
            original = data.original || null;
          if (data.success && data.hasSuggestion) {
            results = { original, suggested };
          }
        } catch (error) {
          // silently capture API error
        }

        return results;
      },
      // FYI this feature is meant to be very passive, form submissions should still happen,
      // even if the verification fails for ANY reason
      async onSubmitAddressForm(event, formType) {
        event.preventDefault();
        let self = this;

        self.formIsLoading = true;

        // verify the entered email address first
        const email = formType === "billing" ? self.cart.billTo.email : self.cart.shipTo.email;
        self.emailMessage = await self.verifyEmail(email);

        // give the user the option to proceed anyways, even if email verification failed
        const emailConfirmMessage = `We were unable to validate your email address ${email}.\n\nAre you sure you want to submit?`;
        if (self.emailMessage?.status === "error") {
          if (confirm(emailConfirmMessage) !== true) {
            self.formIsLoading = false;
            event.preventDefault();
            return false;
          }
        }

        const form = document.wauform;

        const address = formType === "billing" ? self.cart.billTo : self.cart.shipTo;

        // skip address verification step if any of the required inputs are missing.
        if (["addr1", "city", "state", "country", "zip"].find((key) => !address.hasOwnProperty(key) || !address[key])) {
          form.submit();
          return;
        }

        // The required keys exist, so we will verify address first, and then process submission
        event.preventDefault();

        self.formIsLoading = true;

        try {
          const input = {
            ...address,
            state: document.getElementById("state_field_select").value,
            country: document.getElementById("country_field").value,
          };

          const result = await self.verifyAddress(input);

          if (!result || !result.suggested) {
            // Nothing suggested, proceed with form submission
            form.submit();
            return;
          }

          const { original, suggested } = result;

          const updateAddress = async (suggested) => {
            self.formIsLoading = true;

            if (suggested) {
              document.getElementById("addr1").value = suggested.addr1;
              document.getElementById("addr2").value = suggested.addr2;
              document.getElementById("city").value = suggested.city;
              document.getElementById("state_field_select").value = suggested.state;
              document.getElementById("country_field").value = suggested.country;
              document.getElementById("zip_field").value = suggested.zip;
            }

            document.wauform.submit();
          };

          self.$root.$emit("open-address-verification-modal", {
            onUpdateAddress: updateAddress,
            onClose: () => (self.formIsLoading = false),
            original: original,
            suggested: suggested,
          });
        } catch (error) {
          // Error occurred, but submit the form anyways
          form.submit();
        }
      },
    },

    mounted() {
      let self = this;

      // scroll to hash
      document.onreadystatechange = function () {
        if (document.readyState === "complete") {
          if (window.location.hash) {
            let hash = window.location.hash;
            if (hash.substr(0, 1) === "#") {
              let id = hash.replace("#", "");
              self.scrollToId(id);
            }
          }
        }
      };

      // listen for click on <a> with # link
      document.addEventListener("click", function (e) {
        if (!e.target.matches("a")) {
          return;
        }
        let href = e.target.getAttribute("href");
        if (!href || href.charAt(0) !== "#") {
          return;
        }
        e.preventDefault();
        let id = href.replace("#", "");
        self.scrollToId(id);
      });

      // csrf
      window.csrfPromise = new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            let obj = JSON.parse(this.responseText);
            if (obj.name !== undefined && obj.value !== undefined && obj.name !== "" && obj.value !== "") {
              resolve({
                name: obj.name,
                value: obj.value,
              });
            }
          }
        };
        xhr.open("GET", "/actions/site-module/site/get-csrf-token");
        xhr.send();
      });

      // get cart on page load
      self.getCart(() => {
        // on checkout shipping form, check country field for resellers
        self.checkForResellersOnCheckoutShipping(false);
        self.checkForEORIRequired();
      });

      // get CSRF token
      window.csrfPromise.then(function (obj) {
        self.csrfTokenExists = true;
        self.csrfTokenName = obj.name;
        self.csrfTokenValue = obj.value;
      });

      // checkout data for shipping/billing forms
      let addressType = self.getCurrentAddressForm();
      if (addressType) {
        self.getCheckoutFormData(addressType);
      }
    },
    computed: {
      totalCartQuantity() {
        if (!this.cart) {
          return 0;
        }
        let items = this.cart.items || null;
        if (!items) {
          return 0;
        }
        return Object.keys(items).length || 0;
      },
      isEmailVerificationPending() {
        return this.emailMessage && this.emailMessage.status === "pending";
      },
      hasEmailVerificationError() {
        return this.emailMessage && this.emailMessage.status === "error";
      },
      hasEmailVerificationSuccess() {
        return this.emailMessage && this.emailMessage.status === "success";
      },
    },
  });

  return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
